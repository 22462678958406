import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Intropage from './components/intro/Intro';
import Indexerspage from './components/indexers/Indexers';

const IndexPage = () => {
  return (<>
    <Intropage />
  </>)
};

const Indexers = () => {
  return (<>
    <Indexerspage />
  </>)
};

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/indexers" element={<Indexers />} />
      </Routes>
    </BrowserRouter>
  );
};


export default App;
