import { useEffect, useState, useRef, useMemo } from "react";
import { get } from "../../utils/requests";
import RingLoader from "react-spinners/RingLoader";
import TableDashIdx from "../Table-indexers/Table";
import { Link, useNavigate, useParams } from "react-router-dom"

export function Indexerspage() {
  const [loading, setLoading] = useState(true);
  const [getInfo, setGetInfo] = useState("");
  const statusColorMap = {
    online: "green",
    offline: "red"
  };
  const [dark, setDark] = useState(false)
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    get(
      `getdbdata`,
      (response) => {
        setGetInfo(response);
        setLoading(false);
      }
    )

  }, []);

const [isPaused, setPause] = useState(false);
const ws = useRef(null);
const [isConnected, setIsConnected] = useState(false);

const setupWebSocket = () => {
  ws.current = new WebSocket("wss://servers.vfat.io/ws");
  ws.current.onopen = () => {
    console.log("ws opened");
    setIsConnected(true);
  };

  ws.current.onclose = () => {
    console.log("WebSocket closed, attempting to reconnect...");
    setIsConnected(false);
    setTimeout(() => {
      setupWebSocket();
    }, 5000); // Wait for 5 seconds before attempting to reconnect
  };
};

useEffect(() => {
  setupWebSocket();

  return () => {
    if (ws.current) {
      ws.current.close();
    }
  };
}, []);


useEffect(() => {
  if (!ws.current) return;

  ws.current.onmessage = e => {
    try {
      if (isPaused) return;
      const message = JSON.parse(e.data);
      if (message.getinfo) {
        //setGetInfo(message.getinfo);
      }
      if (message.getqueue) {
        //setGetQueue(message.getqueue);
      }
      if (message.getdbdata) {
        setGetInfo(message.getdbdata);
      }
    } catch (e) {
      console.log(e);
    }
  };
}, [isPaused, getInfo]);

useEffect(() => {
  const interval = setInterval(() => {
    window.location.reload();
  }, 1800000);

  return () => clearInterval(interval);
}, []);

  const columns = useMemo(
    () => [
      {
        Header: "Chain ID",
        accessor: "chain_id",
        Cell: ({ cell: { value } }) => (
          <strong>{value}</strong>
        ),
      },
      {
        Header: "Preindexer block",
        accessor: "max_block_number_preindexed",
      },
// <progress className="progress progress-primary w-56" value="0" max="100"></progress>
      {
        Header: "Progress",
        accessor: "pcent",
        Cell: ({ cell: { value } }) => (
          <div className="tooltip" data-tip={`${ value }%`}>
            <progress className={`progress w-56  progress-${value >= 99 ? 'success' : value < 99 ? 'secondary' : value === 0 ? 'primary' : 'error'}`} value={`${value}`} max="100"></progress>
          </div>
        ),
      },
      {
        Header: "Updated at",
        accessor: "updated_at",
      },
    ],
    []
  );



  return (
    <>
      {loading && <div className="grid h-screen place-items-center"><RingLoader
        aria-label="Loading Spinner"
        color="green"
      />
      </div>}

      {!loading && (

        <div className="container mx-auto max-w-full h-full pb-8" data-theme={dark ? "dark" : "light"}>

          <li>
            <label data-toggle-theme="dark,light" class="swap swap-rotate bg-inherit">
              <input data-toggle-theme="dark,light" type="checkbox" onClick={() => setDark(!dark)} /><svg class="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>
              <svg class="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>
            </label>
          </li>

         <Link to="/" className="btn ml-5">
         <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
          <path fillRule="evenodd" d="M13.28 3.97a.75.75 0 010 1.06L6.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0zm6 0a.75.75 0 010 1.06L12.31 12l6.97 6.97a.75.75 0 11-1.06 1.06l-7.5-7.5a.75.75 0 010-1.06l7.5-7.5a.75.75 0 011.06 0z" clipRule="evenodd" />
        </svg>

           Back to home
         </Link>

          <div className="flex flex-wrap mx-2 mb-8 mt-5 content-center w-full">
            <div className="overflow-x-auto w-full">
              <TableDashIdx columns={columns} data={getInfo} />
            </div>
          </div>
        </div>)}
    </>
  );
}

export default Indexerspage;
