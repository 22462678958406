import { useEffect, useState, useRef, useMemo } from "react";
import { get } from "../../utils/requests";
import RingLoader from "react-spinners/RingLoader";
import TableDash from "../Table/Table";
import { Link, useNavigate, useParams } from "react-router-dom"

export function Intropage() {
  const [loading, setLoading] = useState(true);
  const [getInfo, setGetInfo] = useState("");
  const [getQueue, setGetQueue] = useState("");
  const statusColorMap = {
    online: "green",
    offline: "red"
  };
  const [dark, setDark] = useState(false)
  const navigate = useNavigate();
  const params = useParams();

var toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10)
    var hours   = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours,minutes,seconds]
        .map(v => v < 10 ? "0" + v : v)
        .filter((v,i) => v !== "00" || i > 0)
        .join(":")
}


/*  useEffect(() => {
    get(
      `getqueue`,
      (response) => {
        setGetQueue(response);
      }
    )

  }, []);
*/
  useEffect(() => {
    get(
      `getinfo`,
      (response) => {
        setGetInfo(response);
        setLoading(false);
      }
    )

  }, []);

const [isPaused, setPause] = useState(false);
const ws = useRef(null);
const [isConnected, setIsConnected] = useState(false);

const setupWebSocket = () => {
  ws.current = new WebSocket("wss://servers.vfat.io/ws");
  ws.current.onopen = () => {
    console.log("ws opened");
    setIsConnected(true);
  };

  ws.current.onclose = () => {
    console.log("WebSocket closed, attempting to reconnect...");
    setIsConnected(false);
    setTimeout(() => {
      setupWebSocket();
    }, 5000); // Wait for 5 seconds before attempting to reconnect
  };
};

useEffect(() => {
  setupWebSocket();

  return () => {
    if (ws.current) {
      ws.current.close();
    }
  };
}, []);


useEffect(() => {
  if (!ws.current) return;

  ws.current.onmessage = e => {
    try {
      if (isPaused) return;
      const message = JSON.parse(e.data);
      if (message.getinfo) {
        setGetInfo(message.getinfo);
        console.log(message.getinfo);
      }
      if (message.getqueue) {
        setGetQueue(message.getqueue);
      }
      if (message.getdbdata) {
      }
    } catch (e) {
      console.log(e);
    }
  };
}, [isPaused, getInfo, getQueue]);

  const columns = useMemo(
    () => [
      {
        Header: "Chain",
        accessor: "chain",
        Cell: ({ cell: { row, value } }) => (
        <div 
          className="tooltip tooltip-right" 
          data-tip={`ChainID: ${row.original.chain_id}`}
          style={{ position: 'relative', zIndex: 100 }}
        >
          <strong>{value}</strong>
        </div>
        ),
      },
      {
        Header: "contabo1 block",
        accessor: "contabo1_latest_block",
        Cell: ({ cell: { row, value } }) => (
          <p style={{ color: (row.original.contabo1_latest_public_block - value) > 30 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "contabo1 seconds ago",
        accessor: "contabo1_seconds_ago",
        Cell: ({ cell: { value } }) => (
          <p style={{ color: value > 600 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "OVH1",
        accessor: "OVH1_status",
        Cell: ({ cell: { row, value } }) => (
          <div className="tooltip" data-tip={`Block: ${row.original.OVH1_latest_block}, Time ago: ${ toHHMMSS(row.original.OVH1_seconds_ago) }`}>
            <div className={`badge badge-lg badge-${value === 'online' ? 'success' : value === 'delay' ? 'warning bg-warning text-black' : value === 'NOT INSTALLED' ? 'primary' : 'error'}`}>{value}</div>
          </div>
        ),
      },
      {
        Header: "OVH1 block",
        accessor: "OVH1_latest_block",
        Cell: ({ cell: { row, value } }) => (
          <p style={{ color: (row.original.contabo1_latest_public_block - value) > 30 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "OVH1 seconds ago",
        accessor: "OVH1_seconds_ago",
        Cell: ({ cell: { value } }) => (
          <p style={{ color: value > 600 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "s23M",
        accessor: "s23M_status",
        Cell: ({ cell: { row, value } }) => (
          <div className="tooltip" data-tip={`Block: ${row.original.s23M_latest_block}, Time ago: ${ toHHMMSS(row.original.s23M_seconds_ago) }`}>
            <div className={`badge badge-lg badge-${value === 'online' ? 'success' : value === 'delay' ? 'warning bg-warning text-black' : value === 'NOT INSTALLED' ? 'primary' : 'error'}`}>{value}</div>
          </div>
        ),
      },
      {
        Header: "s23M block",
        accessor: "s23M_latest_block",
        Cell: ({ cell: { row, value } }) => (
          <p style={{ color: (row.original.contabo1_latest_public_block - value) > 30 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "s23M seconds ago",
        accessor: "s23M_seconds_ago",
        Cell: ({ cell: { value } }) => (
          <p style={{ color: value > 600 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "RIP",
        accessor: "RIP_status",
        Cell: ({ cell: { row, value } }) => (
          <div className="tooltip" data-tip={`Block: ${row.original.RIP_latest_block}, Time ago: ${ toHHMMSS(row.original.RIP_seconds_ago) }`}>
            <div className={`badge badge-lg badge-${value === 'online' ? 'success' : value === 'delay' ? 'warning bg-warning text-black' : value === 'NOT INSTALLED' ? 'primary' : 'error'}`}>{value}</div>
          </div>
        ),
      },
      {
        Header: "RIP block",
        accessor: "RIP_latest_block",
        Cell: ({ cell: { row, value } }) => (
          <p style={{ color: (row.original.contabo1_latest_public_block - value) > 30 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "RIP seconds ago",
        accessor: "RIP_seconds_ago",
        Cell: ({ cell: { value } }) => (
          <p style={{ color: value > 600 ? 'red' : '' }}>{value}</p>
        ),
      },
      {
        Header: "Public block",
        accessor: "contabo1_latest_public_block",
      },
    ],
    []
  );



  return (
    <>
      {loading && <div className="grid h-screen place-items-center"><RingLoader
        aria-label="Loading Spinner"
        color="green"
      />
      </div>}

      {!loading && (

        <div className="container mx-auto max-w-full h-full pb-8" data-theme={dark ? "dark" : "light"}>

          <li>
            <label data-toggle-theme="dark,light" class="swap swap-rotate bg-inherit">
              <input data-toggle-theme="dark,light" type="checkbox" onClick={() => setDark(!dark)} /><svg class="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z" /></svg>
              <svg class="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z" /></svg>
            </label>
          </li>
         <Link to="/indexers" className="btn ml-5">
           Go to indexers
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
            <path fillRule="evenodd" d="M4.72 3.97a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L11.69 12 4.72 5.03a.75.75 0 010-1.06zm6 0a.75.75 0 011.06 0l7.5 7.5a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 11-1.06-1.06L17.69 12l-6.97-6.97a.75.75 0 010-1.06z" clipRule="evenodd" />
          </svg>
         </Link>

{/*
          <div className="flex flex-wrap mx-2 mb-8 mt-5 justify-center items-center">
            <div className="stats shadow">
              {Object.entries(getQueue).map(([key, value]) => {
                return (<div className="stat bg-base-300" key={key}>
                  <div className="stat-title"><strong>{key}</strong></div>
                  <div className="stat-value text-accent">{value}</div>
                </div>
                );
              })}
            </div>
          </div>
*/}

          <div className="flex flex-wrap mx-2 mb-8 mt-5 justify-center items-center">
            {Object.entries(getInfo).map(([key, val]) => (
              !["nodes", "contabo1"].includes(key)  ? (
                <div className="card w-96 bg-base-300 shadow-xl mt-5 mr-2" key={key}>
                  <div className="card-body items-center text-center">
                    <h2 className="card-title">{key}</h2>
                    {val.status === 'ONLINE' && <div className="badge badge-success">{val.status}</div>}
                    {val.status === 'OFFLINE' && <div className="badge badge-error">{val.status}</div>}
                    <h4>RAM Use %</h4>
                    <progress className="progress progress-success w-80" value={`${val.memory.pcent}`} max="100"></progress>
                    <h4>Load avg.</h4>
                    <progress className="progress progress-success w-80" value={`${val.memory.loadavg}`} max="100"></progress>
                    <label htmlFor={`"my-modal-${key}"`} className="btn">Show Disks</label>
                    <input type="checkbox" id={`"my-modal-${key}"`} className="modal-toggle" />
                    <div className="modal">
                      <div className="modal-box relative">
                        <label htmlFor={`"my-modal-${key}"`} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>


                        <div className="overflow-x-auto mr-2 ml-2">
                          <table className="table table-compact w-full">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Drive</th>
                                <th>Space</th>
                                <th>Usage</th>
                              </tr>
                            </thead>
                            <tbody>


                              {Object.keys(val.disk).length > 0 && Object.entries(val.disk.info).map(([key, val]) => (<tr className="hover" key={key}>
                                <th></th>
                                <td>{key}</td>
                                <td>{val[0]}</td>
                                <td>{val[1]}</td>

                              </tr>))}

                            </tbody>

                          </table>
                        </div>


                      </div>
                    </div>

                  </div>
                </div>
              ) : null

            ))}
          </div>


          <div className="flex flex-wrap mx-2 mb-8 mt-5 content-center w-full">
            <div className="overflow-x-auto w-full">
              <TableDash columns={columns} data={getInfo.nodes} />
            </div>
          </div>
        </div>)}
    </>
  );
}

export default Intropage;
